.app-layout_gradient {
  z-index: -10;
  background: linear-gradient(
      0deg,
      #00bbf1 0%,
      rgba(46, 44, 115, 0.979167) 100%
    )
    400px;
  position: fixed;
  height: 270px;
  width: 100%;
}
