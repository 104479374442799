.attach-file_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.attach-file_title {
  padding: 10px 0px 16px;
}

.attach-file_image-preview {
  max-width: 550px;
  max-height: 550px;
}

.attach-file_filename {
  margin: 16px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.attach-file_icon {
  display: flex;
  border: 1px solid #999;
  width: 38px;
  height: 38px;
  padding-top: 4px;
  justify-content: center;
  border-radius: 8px;
  position: absolute;
}

.attach-file_input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.attach-file_input {
  opacity: 0;
  width: 30px;
  height: 30px;
  z-index: 3;
}
